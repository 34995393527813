<template>
<b-container style="position:absolute;">
    <b-row style="padding:75px 75px 0px 75px;">
        <div>
            <img src="/assets/images/defensil-logo.jpg" class='defensil-logo'>
        </div>
        <b-col style="color:white;font-size:3.5rem;">
            <p>This page is an illegal site</p>
            <p>Please contact Defensil Gaming for your casino gaming needs.</p>

        </b-col>
    </b-row>
</b-container>
</template>

<script>
export default {
    name: 'PageWarning',
}
</script>

<style>
html {
    background: url(/assets/images/loading/loading-background.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.defensil-logo{
    height: 15vh;
}
</style>
